<template>
  <div class="home">
    <swiper class="main-swiper" :options="swiperOption">
      <swiper-slide class="swiper-slide">
        <div class="swiper-item swiper-1"></div>
        <div class="down-icon"></div>
      </swiper-slide>
      <swiper-slide class="swiper-slide">
        <div class="swiper-item swiper-2">
          <div class="main-box">
            <div class="title">
              <img src="@/assets/img/title-1.png" alt="游戏介绍" />
            </div>
            <div class="introduction-box clearfix">
              <div class="introduction-left">
                <div class="icon">
                  <img src="@/assets/img/pop-icon.png" alt="" />
                </div>
                <p class="name">宝贝乐消消</p>
                <p class="tip">超萌宝贝，百变消除</p>
              </div>
              <div class="introduction-right">
                <p class="name">全新消除游戏《宝贝乐消消》惊喜上线！</p>
                <p class="desc">
                  《宝贝乐消消》为您带来更加爽快的消除体验——百余个精心设计的关卡、更多特效合成、一路砰砰扫清各种障碍！在这里不仅可以享受闯关的畅快，还可以建造各种精美场景，更可以为您的宝贝搭配各种装扮、体验时尚换装的乐趣，是您休闲娱乐的绝佳选择！
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="down-icon"></div>
      </swiper-slide>
      <swiper-slide class="swiper-slide">
        <div class="swiper-item swiper-3">
          <div class="main-box">
            <div class="title">
              <img src="@/assets/img/title-2.png" alt="游戏特色" />
            </div>
            <div class="swiper-box">
              <div class="middle-swiper-box">
                <swiper class="box-swiper" :options="boxOption">
                  <swiper-slide class="swiper-slide">
                    <img src="@/assets/img/pic1.jpg" alt="" />
                  </swiper-slide>
                  <swiper-slide class="swiper-slide">
                    <img src="@/assets/img/pic2.jpg" alt="" />
                  </swiper-slide>
                  <swiper-slide class="swiper-slide">
                    <img src="@/assets/img/pic3.jpg" alt="" />
                  </swiper-slide>
                  <swiper-slide class="swiper-slide">
                    <img src="@/assets/img/pic4.jpg" alt="" />
                  </swiper-slide>
                  <swiper-slide class="swiper-slide">
                    <img src="@/assets/img/pic5.jpg" alt="" />
                  </swiper-slide>
                </swiper>
                <div slot="button-prev" class="swiper-button-prev"></div>
                <div slot="button-next" class="swiper-button-next"></div>
                <div
                  slot="pagination"
                  class="swiper-pagination box-pagination"
                ></div>
              </div>
            </div>
          </div>
        </div>
      </swiper-slide>
      <div class="swiper-pagination main-pagination" slot="pagination"></div>
    </swiper>
  </div>
</template>

<script>
import { swiper, swiperSlide } from "vue-awesome-swiper";
import "swiper/dist/css/swiper.css";

export default {
  components: {
    swiper,
    swiperSlide,
  },
  data() {
    return {
      swiperOption: {
        initialSlide: 0,
        direction: "vertical",
        mousewheel: true,
        speed: 800,
        loop: false,
        pagination: {
          el: ".main-pagination",
          clickable: true,
        },
      },
      boxOption: {
        slidesPerView: 2,
        paginationClickable: true,
        spaceBetween: 100,
        loop: true,
        autoplay: {
          delay: 5000,
          disableOnInteraction: false
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        pagination: {
          el: ".box-pagination",
          clickable: true,
        },
      },
    };
  }
};
</script>
<style lang="scss" scoped>
@import "index.scss";
</style>
